import type { MenuOption } from '@/types'

export const getSettingsMenuOptions = (): MenuOption[] => {
  return [
    { iconName: 'mdi:radar', label: $t('domain', 2), href: '/settings/domains', parentGroups: ['settings'] },
    { iconName: 'mdi:file-document-arrow-right-outline', label: $t('logForwarder', 2), href: '/settings/log-forwarders', parentGroups: ['settings'] },
    { iconName: 'mdi:source-branch', label: $t('sourceCode'), href: '/settings/source-code-connections', parentGroups: ['settings'] },
    { iconName: 'mdi:account-outline', label: $t('account'), href: '/settings/account', parentGroups: ['settings'] },
    { iconName: 'mdi:account-supervisor-outline', label: $t('user', 2), href: '/settings/users', parentGroups: ['settings'] },
    { iconName: 'mdi:key-chain', label: $t('apiKey', 2), href: '/settings/apikeys', parentGroups: ['settings'] },
    { iconName: 'mdi:robot-excited-outline', label: $t('aiAgent', 2), href: '/settings/aiagents', parentGroups: ['settings'] },
  ]
}

export const getAdminMenuOptions = (
  isGhostAdmin: boolean,
): MenuOption[] => {
  const options = []

  if (isGhostAdmin) {
    options.push({ iconName: 'mdi:flask-empty-outline', label: $t('beta'), href: '/settings/flags', parentGroups: ['settings', 'admin'] })
    options.push({ iconName: 'mdi:sitemap-outline', label: $t('organization', 2), href: '/settings/organizations', parentGroups: ['settings', 'admin'] })
  }

  options.push({ iconName: 'mdi:eye', label: $t('auditLog'), href: '/settings/auditlog', parentGroups: ['settings', 'admin'] })

  return options
}
