<template>
  <div class="flex justify-center gap-x-8">
    <div class="inline-flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary-400 hover:text-secondary-200">
      <Icon
        v-tooltip="'Close Navigation'"
        name="mdi:arrow-expand-left"
        class="cursor-pointer"
        @click="navigationStore.showSideNav = !navigationStore.showSideNav"
      />
    </div>
    <a class="inline-flex cursor-pointer items-center justify-center rounded-full p-2 text-secondary-400 hover:text-secondary-200">
      <Icon name="mdi:account-circle" @click="handleMenuClick" />
    </a>
    <a class="inline-flex cursor-pointer items-center justify-center rounded-full text-secondary-400 hover:text-secondary-200">
      <ThemeButton />
    </a>
  </div>

  <Popover ref="menu" class="rounded border border-secondary-300 dark:border-secondary-700" :pt="{ content: { class: '!p-0' } }">
    <button
      class="relative flex w-full cursor-pointer items-start overflow-hidden rounded-t-lg border-0 bg-transparent px-4 py-2"
      @click="handleAccountClick"
    >
      <gs-label-lg>
        {{ currentUser?.name }}
      </gs-label-lg>
    </button>

    <div class="px-4 pb-2">
      <Select
        v-model="selectedOrg"
        size="small"
        option-label="label"
        :options="orgOptions"
        :disabled="orgOptions.length <= 1"
        @change="handleOrgSelected($event.value)"
      />
    </div>

    <button
      class="flex w-full content-center rounded-b-lg border-t border-secondary-300 px-4 py-2 text-secondary-500 hover:bg-secondary-100 hover:text-secondary-800 dark:border-secondary-700 dark:hover:bg-secondary-800 dark:hover:text-secondary-50"
      data-test="logout"
      @click="handleLogout"
    >
      <Icon name="mdi:logout" class="mr-2" />
      <gs-label-md>
        {{ $t('signOut') }}
      </gs-label-md>
    </button>
  </Popover>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import useUsersStore from '@/stores/users'
import useNavigationStore from '@/stores/navigation'

const usersStore = useUsersStore()
const navigationStore = useNavigationStore()
const router = useRouter()
const { getAccessTokenSilently, logout } = useAuth0()
const { currentUser } = storeToRefs(usersStore)
const menu = ref()

const handleAccountClick = () => {
  router.push('/settings/account')
  menu.value.toggle()
}

const handleMenuClick = (event: Event) => {
  menu.value.toggle(event)
}

const handleLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } })
}

const orgOptions = currentUser.value?.organizations?.map(org => ({ value: org.auth0_id, label: org.name })) || []

// Sort the organizations alphabetically
orgOptions.sort((a, b) => a.label.localeCompare(b.label))

const selectedOrg = ref(orgOptions.find(org => org.value === currentUser.value?.organization?.auth0_id))

const handleOrgSelected = async (option: { value: string, label: string }) => {
  // Do nothing if the selected organization is the same as the current one
  if (currentUser.value?.organization?.auth0_id === option.value) {
    return
  }

  try {
    await getAccessTokenSilently({
      authorizationParams: {
        organization: option.value,
        ignoreCache: true,
      },
      cacheMode: 'off',
    })
  } catch (error) {
    useErrorToast($t('changeOrganizationError'))
    console.error(error)
    // Reset the selected organization
    selectedOrg.value = orgOptions.find(org => org.value === currentUser.value?.organization?.auth0_id)
    return
  }

  // Route to main page and refresh
  await router.push({ path: '/' })
  router.go(0)
}
</script>
