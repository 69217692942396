<template>
  <div v-if="navItem.enabled === undefined || navItem.enabled">
    <li v-if="!navItem.subItems">
      <NavigationMenuItem :nav-item="navItem" :selected="selectedNavItem?.href === navItem.href" />
    </li>

    <!-- Nav item with children -->
    <li v-else>
      <a
        :id="`nav-group-accordion-${navItem.id}`"
        v-styleclass="{
          selector: '@next',
          enterFromClass: 'hidden',
          enterActiveClass: 'animate-slidedown',
          leaveToClass: 'hidden',
          leaveActiveClass: 'animate-slideup',
        }"
        class="flex cursor-pointer items-center p-3 text-secondary-300 transition-colors duration-150 rounded-border hover:bg-secondary-800 hover:text-secondary-50"
        @click="handleNavigateToFirstSubItem(navItem)"
      >
        <Icon v-if="navItem?.iconName" :name="navItem.iconName" class="mr-2" />
        <gs-label-md class="font-medium text-secondary-300 hover:text-secondary-50">{{ navItem.label }}</gs-label-md>
        <Icon name="mdi:chevron-down" class="ml-auto" />
      </a>

      <ul class="m-0 mt-1 hidden list-none overflow-y-hidden py-0 pl-4 pr-0 transition-all duration-[400ms] ease-in-out">
        <template v-for="subItem in navItem.subItems" :key="subItem.label">
          <NavigationRecursiveSection :nav-item="subItem" :selected-nav-item="selectedNavItem" />
        </template>
      </ul>
    </li>
  </div>
</template>

<script setup lang="ts">
import type { MenuOption } from '@/types'

defineProps({
  navItem: {
    type: Object as PropType<MenuOption>,
    required: true,
  },
  selectedNavItem: {
    type: Object as PropType<MenuOption>,
    default: null,
  },
})

const router = useRouter()

const handleNavigateToFirstSubItem = (navItem: MenuOption) => {
  // When the menu is first clicked on, the unordered list will have the class "hidden"
  const menuClosed = document.querySelector(`#nav-group-accordion-${navItem.id}`)?.nextElementSibling?.classList.contains('hidden')
  // If we have subitems and the parent menu's list is hidden, go to the first sub item (unless settings)
  if (navItem.subItems?.length && menuClosed) {
    if (navItem.id === 'settings') {
      // If this is the settings parent menu, go to the users page (not triage)
      router.push({ path: '/settings/domains' })
    } else {
      const firstSubItem = navItem.subItems?.at(0)
      router.push({ path: firstSubItem?.href, query: firstSubItem?.queryParams })
    }
  }
}
</script>
